<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Adicionar Anexo</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="attachment.name"
                  label="Título do Anexo"
                  outlined
                  dense
                  hide-details
                  :rules="rules.name"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="attachment.file"
                  label="Anexar"
                  outlined
                  dense
                  :rules="rules.file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CLIENT from '@/services/client'

const defaultData = () => ({
  name: '',
  file: null,
})

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: true,
    attachment: defaultData(),
    rules: {
      name: [
        v => !!v || 'O título do anexo é obrigatório',
        v => v.length <= 50 || 'O título do anexo deve ter no máximo 100 caracteres',
      ],
      file: [
        v => !!v || 'O anexo é obrigatório',
        v => (!!v && v.size <= 10485760) || 'O anexo deve ter no máximo 10MB',
      ],
    },
  }),
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.attachment.file) throw new Error('O anexo é obrigatório')
        var response = await CLIENT.attachment.upload(this.client.id, this.attachment)
        this.$emit('success', response.attachment)
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar pagamento',
        })
        this.$emit('error', error)
      }
    },
    open() {
      this.attachment = defaultData()
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
    },
  },
  mounted() {
    this.$parent.$on('add-attachment', this.open)
  },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style></style>
